




































import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import axios from 'axios'

import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'

@Component({
  components: {
    PasswordConfirmField,
    NotifyOnFailed,
    ValidationProvider,
    ValidationObserver
  }
})
export default class ToolsIndex extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  // @Ref() readonly observer!: typeof ValidationObserver
  // form: Record<string, any> = {
  //   confirm: '',
  //   debug: true,
  //   invites: '',
  //   errors: {
  //     confirm: '',
  //     debug: '',
  //     invites: ''
  //   }
  // }

  // done = true

  // created () {
  //   if (process.env.NODE_ENV === 'development') {
  //     this.form.invites = 'HOSPITAL ORTOPEDICO E MEDICINA ESPECIALIZADA - HOME;HOSPITAL ORTOPEDICO E MEDICINA ESPECIALIZADA - HOME;37.108.388/0001-59;SGAS Quadra 613 - Conjunto C - Asa Sul;74.075-030;DF;BRASÍLIA;zxcvb@sbqapi.test;(61)3878-2878;https://www.homehospital.com.br/;2;DOUTOR MEDICO ZXCVB;(61)999999999;zxcvb@sbqapi.test\nHOSPITAL ORTOPEDICO DE GOIÂNIA;HOSPITAL ORTOPEDICO DE GOIÂNIA LTDA;01.586.742/0001-03;Av. L, 470 - St. Aeroporto;74.210-030;GO;Goiânia;coremehog@gmail.com;(62)3254-5669;http://hog.com.br/;2;DOUTOR MEDICO ABC;(62)999999999;asdfg@sbqapi.test\nINSTITUTO ORTOPÉDICO DE GOIÂNIA;IOG - Instituto Ortopédico de Goiânia;01.570.589/0001-26;R. T-27, 819 - St. Bueno;74.215-010;GO;Goiânia;qwert@sbqapi.test;(62)3252-5050;https://www.iognet.com.br/;1;DOUTOR MEDICO QWERT;(62)999999999;qwert@sbqapi.test'
  //   }
  // }

  // submit () {
  //   this.done = false
  //   this.clearErrors()
  //   axios.post('/tools/services/batch', this.form)
  //     .then(response => {
  //       console.log(response.data.data)

  //       const message: flashMessage = {
  //         message: `Operação realizada com sucesso. ${this.form.debug ? 'Verifique o console' : ''}`,
  //         type: 'success'
  //       }
  //       this.setFlashMessage(message)
  //       this.clearForm()
  //     })
  //     .catch(err => this.handleSubmitError(err))
  //     .finally(() => { this.done = true })
  // }

  // handleSubmitError (err: any) {
  //   const response = err.response

  //   const errors = response.data.errors
  //   if (errors) {
  //     Object.entries(errors).map(([key, value]: [string, any]) => {
  //       this.form.errors[key] = value[0]
  //     })
  //   }

  //   if (response.status === 403) {
  //     this.form.errors = response.data.data
  //   }
  // }

  // clearErrors () {
  //   this.form.errors = {
  //     confirm: '',
  //     debug: '',
  //     invites: ''
  //   }
  // }

  // $refs!: {
  //   observer: any;
  // }

  // clearForm () {
  //   this.form.confirm = ''
  //   this.form.invites = ''
  //   this.$refs.observer.reset()
  // }
}
